<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import request1 from './request-1.vue'

export default {
  page: {
    title: "Reports",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    request1
  },
  data() {
    return {
      pagetitle: "Звіти"
    };
  },
  created(){
   
  },
  computed :{
   
  },
  methods: {
   
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle" :is-busy="false">
    <div class="row">
      <div class="col">
        <request1>

          
        </request1>
      </div>
    </div>
  </Layout>
</template>
